<!-- 支付配置 -->

<template>
	<div class="payment main-cnt">
		<div class="content">
			<common-table ref="chargeTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
				:apiName="SystemManage.getPayConfigLists" :columns="tableColumns" @onAssociApplet="onAssociApplet"
				@onPowStation="onPowStation" @onBindApplet="onBindApplet" @onPowerStationBtn="onPowerStationBtn"
				@onEditAdmin="onEditAdmin">
			</common-table>
		</div>

		<!-- 关联小程序 -->
		<w-dialog ref="refAppletDialog" class="add-dialog" title="小程序关联明细" width="40%" btnWidth="140px" top="10vh"
			:hideFooter="true">
			<common-table ref="appletTable" :ispaging="false" :apiName="SystemManage.getAppletLists"
				:columns="appletColumns" @onUnbindBtn="onAppletUnbindBtn" tableHeight="450"
				:extraParame="extraParameApplet">
			</common-table>
		</w-dialog>

		<!-- 关联电站 -->
		<w-dialog ref="refPowStationDialog" class="add-dialog" title="关联电站明细" width="50%" btnWidth="140px" top="10vh"
			:hideFooter="true">
			<el-input v-model="extraParameStation.keywords" placeholder="请输入电站名称或编号" clearable @input="powStationChange"
				class="cell-30 s-m-b">
			</el-input>
			<common-table ref="powStationTable" :ispaging="false" :apiName="SystemManage.getStationLists"
				:columns="powStationColumns" @onPowStationBtn="onPowStationBtn" tableHeight="450"
				:extraParame="extraParameStation">
			</common-table>
		</w-dialog>

		<!-- 绑定小程序 -->
		<w-dialog ref="refBindAppletDialog" class="add-dialog" title="绑定小程序" width="40%" btnWidth="140px" top="10vh"
			@wConfirm="onConfirmBindApplet">
			<el-table :data="tableBindApplet" stripe style="width: 100%" element-loading-text="数据加载中"
				tooltip-effect="light" @selection-change="bindAppletSelectionChange" height='450'
				:row-key="getBindAppletKeys">
				<el-table-column type="selection" width="55" :reserve-selection="true" />
				<el-table-column prop="mi_name" label="小程序名称" show-overflow-tooltip />
			</el-table>
			<div class="s-m-t">已选<span class="select-quant title-num-green">{{ selectBindApplet.length }}</span></div>
		</w-dialog>

		<!-- 绑定电站 -->
		<w-dialog ref="refStationDialog" class="add-dialog" title="绑定电站" width="50%" btnWidth="140px" top="10vh"
			@wConfirm="onConfirmStation">
			<el-table :data="tableStation" stripe style="width: 100%" element-loading-text="数据加载中"
				tooltip-effect="light" @selection-change="stationSelectionChange" height='450'
				:row-key="getStationKeys">
				<el-table-column type="selection" width="55" :reserve-selection="true" />
				<el-table-column prop="s_name" label="电站名称" show-overflow-tooltip />
				<el-table-column prop="s_no" label="电站编号" show-overflow-tooltip />
				<el-table-column prop="region" label="区域" show-overflow-tooltip />
			</el-table>
			<div class="s-m-t">已选<span class="select-quant title-num-green">{{ selectStation.length }}</span></div>
		</w-dialog>

		<!-- 编辑管理员 -->
		<w-dialog ref="refEditAdmin" class="add-dialog" title="编辑管理员" width="50%" btnWidth="140px" top="10vh"
			@wConfirm="onConfirmEditAdmin">
			<div>
				<div class="pow-title">管理人员</div>
				<el-select clearable filterable remote reserve-keyword v-model="keywords" placeholder="请输入用户姓名"
					remote-show-suffix :remote-method="searchManage" @change="changeManage">
					<el-option v-for="(item,index) in manageList" :key="index" :label="item.u_name"
						:value="item.u_id" />
				</el-select>
				<div class="contents border-bottom">
					<el-button class="active-skin" v-for="(item,i) in onfirmManage" :key="i">
						<div class="del-btn" @click="delManage(item, i)">x</div>
						{{item.u_name}}
					</el-button>
				</div>
			</div>
		</w-dialog>
	</div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		computed,
		watch,
		nextTick
	} from "vue";
	import {
		SystemManage
	} from "@/plugins/api.js";
	import {
		ElMessage,
		ElMessageBox
	} from "element-plus";
	import {
		useStore
	} from "vuex";
	import {
		unique,
	} from "@/utils/common.js";

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	watch(
		() => menuTokens.value,
		(data) => {
			if (data.length) {
				authData.value = data;
			}
		}, {
		deep: true,
		immediate: true,
	}
	);
	const chargeTable = ref(null); // 表格对象
	/** 表格配置数据 */
	const tableColumns = ref([{
		prop: "mp_id",
		label: "ID",
		color: "--text-color",
	},
	{
		prop: "mp_mch_name",
		label: "商户名称",
		color: "--text-color",
	},
	{
		prop: "mp_wx_mch_id",
		label: "微信普通商户商户号",
		color: "--text-color",
	},
	{
		prop: "mp_remark",
		label: "备注说明",
		color: "--text-color",
	},
	{
		type: "block",
		prop: "mini_count",
		label: "关联小程序",
		minWidth: 60,
		active: "onAssociApplet",
		token: "members",
	},
	{
		type: "block",
		prop: "station_count",
		label: "关联电站",
		minWidth: 60,
		active: "onPowStation",
		token: "members",
	},
	{
		prop: "manager_name",
		label: "管理员",
		color: "--text-color",
	},
	{
		type: "operation",
		prop: "",
		label: "操作",
		minWidth: 100,
		bottons: [{
			name: "编辑管理员",
			action: "onEditAdmin",
			token: "members",
		},
		],
	},
	]);

	/** 关联小程序 */
	const refAppletDialog = ref(null); // 弹框对象
	const appletTable = ref(null); // 表格对象
	const extraParameApplet = ref({
		mp_id: '',
		type: 1
	}); // 自定义参数
	const appletColumns = ref([ // 表格配置
		{
			prop: "mi_name",
			label: "小程序名称",
			color: "--text-color",
		},
		// {
		// 	type: "operation",
		// 	prop: "",
		// 	label: "操作",
		// 	minWidth: 100,
		// 	bottons: [{
		// 		name: "解绑",
		// 		action: "onUnbindBtn",
		// 		token: "members",
		// 	}, ],
		// },
	]);

	/** 关联电站 */
	const refPowStationDialog = ref(null); // 弹框对象
	const powStationTable = ref(null); // 表格对象
	const extraParameStation = ref({
		mp_id: '',
		keywords: '',
		type: 1
	}); // 自定义参数
	const powStationColumns = ref([ // 表格配置
		{
			prop: "s_name",
			label: "电站名称",
			color: "--text-color",
		},
		{
			prop: "s_no",
			label: "电站编号",
			color: "--text-color",
		},
		{
			prop: "province_name",
			label: "区域",
			color: "--text-color",
		},
		{
			type: "operation",
			prop: "",
			label: "操作",
			minWidth: 100,
			bottons: [{
				name: "解绑",
				action: "onPowStationBtn",
				token: "members",
			},],
		},
	]);

	/** 绑定小程序 */
	const refBindAppletDialog = ref(null); // 弹框对象
	const tableBindApplet = ref([]); // 表格数据
	const selectBindApplet = ref([]); // 选中绑定小程序数据
	const currentPayment = ref({}); // 当前绑定行支付数据

	/** 绑定电站 */
	const refStationDialog = ref(null); // 弹框对象
	const tableStation = ref([]); // 表格数据
	const selectStation = ref([]); // 选中绑定电站数据
	const currentStation = ref({}); // 当前绑定行支付数据

	/** 编辑管理员 */
	const refEditAdmin = ref(null);  // 编辑管理员弹框
	const keywords = ref('');  // 关键词
	const manageList = ref([]);  // 列表
	const onfirmManage = ref([]);  // 列表
	/**
	 * 
	 * 点击关联小程序
	 * 
	 * */
	const onAssociApplet = (row) => {
		refAppletDialog.value.show();
		nextTick(() => {
			extraParameApplet.value.mp_id = row.mp_id;
			appletTable.value.tableLoad();
		});
	}
	/**
	 * 
	 * 关联小程序解绑
	 * 
	 * */
	const onAppletUnbindBtn = (row) => {
		ElMessageBox.confirm(
			`确认将该电站和小程序解绑，解绑后小程序将无法查看该站！`,
			'解绑确认', {
			confirmButtonText: '确认',
			cancelButtonText: '取消',
		}
		)
			.then(() => {
				SystemManage.unBindApplet({
					mi_id: row.mi_id
				}).then((res) => {
					if (res.Code === 200) {
						ElMessage.success(`解绑成功`); // 成功提示
						refAppletDialog.value.close();
						chargeTable.value.tableLoad();
					} else {
						ElMessage.error(res.Message);
					}
				});
			})
			.catch(() => {

			})
	}
	/**
	 * 
	 * 点击关联电站
	 * 
	 * */
	const onPowStation = (row) => {
		refPowStationDialog.value.show();
		nextTick(() => {
			extraParameStation.value.mp_id = row.mp_id;
			powStationTable.value.tableLoad();
		});
	}
	const powStationChange = () => {
		powStationTable.value.tableLoad();
	}
	/**
	 * 
	 * 关联电站解绑
	 * 
	 * */
	const onPowStationBtn = (row) => {
		ElMessageBox.confirm(
			`确认将该电站和小程序解绑，解绑后小程序将无法查看该站！`,
			'解绑确认', {
			confirmButtonText: '确认',
			cancelButtonText: '取消',
		}
		)
			.then(() => {
				SystemManage.unBindStation({
					s_id: row.s_mp_id
				}).then((res) => {
					if (res.Code === 200) {
						ElMessage.success(`解绑成功`); // 成功提示
						refPowStationDialog.value.close();
						chargeTable.value.tableLoad();
					} else {
						ElMessage.error(res.Message);
					}
				});
			})
			.catch(() => {

			})
	}
	/**
	 * 
	 * 绑定小程序
	 * 
	 * */
	const onBindApplet = (row) => {
		currentPayment.value = row;
		SystemManage.getAppletLists({
			mp_id: row.mp_id,
			type: 2
		}).then((res) => {
			if (res.Code === 200) {
				tableBindApplet.value = res.Data ? res.Data : [];
				selectBindApplet.value = [];
				refBindAppletDialog.value.show();
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	const getBindAppletKeys = (row) => {
		return row.mp_id;
	}
	/**
	 * 
	 * 选择绑定小程序
	 * 
	 * */
	const bindAppletSelectionChange = (val) => {
		selectBindApplet.value = val;
	}
	/**
	 * 
	 * 绑定小程序确认按钮
	 * 
	 * */
	const onConfirmBindApplet = () => {
		if (selectBindApplet.value.length == 0) {
			ElMessage.error("请选择绑定数据");
			return;
		}
		refBindAppletDialog.value.isLoading = true;
		// 处理id
		let arr = [];
		selectBindApplet.value.forEach((item) => {
			arr = arr.concat(item.mi_id);
		});
		let params = {
			mp_id: currentPayment.value.mp_id,
			bind_id: arr.join(","),
		};
		SystemManage.bindApplet(params).then((res) => {
			refBindAppletDialog.value.isLoading = false;
			if (res.Code === 200) {
				refBindAppletDialog.value.close();
				chargeTable.value.tableLoad();
				ElMessage.success("绑定成功！");
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 绑定电站
	 * 
	 * */
	const onPowerStationBtn = (row) => {
		currentStation.value = row;
		SystemManage.getStationLists({
			mp_id: row.mp_id,
			type: 2
		}).then((res) => {
			if (res.Code === 200) {
				tableStation.value = res.Data ? res.Data : [];
				if (tableStation.value.length > 0) {
					tableStation.value.forEach(item => {
						item.region = item.city_name + item.county_name;
					})
				}
				selectStation.value = [];
				refStationDialog.value.show();
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	const getStationKeys = (row) => {
		return row.mp_id;
	}
	/**
	 * 
	 * 选择绑定电站
	 * 
	 * */
	const stationSelectionChange = (val) => {
		selectStation.value = val;
	}
	/**
	 * 
	 * 绑定电站确认按钮
	 * 
	 * */
	const onConfirmStation = () => {
		if (selectStation.value.length == 0) {
			ElMessage.error("请选择绑定数据");
			return;
		}
		refStationDialog.value.isLoading = true;
		// 处理id
		let arr = [];
		selectStation.value.forEach((item) => {
			arr = arr.concat(item.s_id);
		});
		let params = {
			mp_id: currentStation.value.mp_id,
			bind_id: arr.join(","),
		};
		SystemManage.bindStation(params).then((res) => {
			refStationDialog.value.isLoading = false;
			if (res.Code === 200) {
				refStationDialog.value.close();
				chargeTable.value.tableLoad();
				ElMessage.success("绑定成功！");
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	const currentRow = ref({});  // 当前编辑数据
	/**
	 * 
	 * 点击编辑管理员按钮
	 * 
	 * */
	const onEditAdmin = (row) => {
		currentRow.value = row;
		keywords.value = '';
		manageList.value = [];
		onfirmManage.value = [];
		getManagerList({ mp_id: row.mp_id, type: '1', keywords: keywords.value }, 'query');
	}
	/**
	 * 
	 * 获取人员数据
	 * 
	 * */
	const getManagerList = (data, type) => {
		SystemManage.getManagerList(data).then(res => {
			if (res.Code == 200) {
				if (type == 'query') {
					// 获取
					onfirmManage.value = res.Data ? res.Data : [];
					refEditAdmin.value.show();
				} else {
					// 查询
					manageList.value = res.Data ? res.Data : [];
				}
			}
		})
	}
	/**
	 * 
	 * 选择管理员
	 * 
	 * */
	const changeManage = (e) => {
		if (!e) return false;
		if (manageList.value.length == 0) return false;
		if (manageList.value.length > 0) {
			const row = manageList.value.filter(res => {
				if (res.u_id == e) {
					return res;
				}
			});
			if (row && row[0]) {
				onfirmManage.value.push(row[0])
			}
			onfirmManage.value = onfirmManage.value.reduce((acc, obj) => {
				const existingObj = acc.find(item => item.u_id === obj.u_id);
				if (!existingObj) {
					acc.push(obj);
				}
				return acc;
			}, []);
		}
	}
	/**
	 * 
	 * 搜索管理员
	 * 
	 * */
	const searchManage = (e) => {
		getManagerList({ mp_id: currentRow.value.mp_id, type: '2', keywords: e }, 'search');
	}
	/**
	 * 
	 * 解绑管理员
	 * 
	 * */
	const delManage = (item, i) => {
		onfirmManage.value.splice(i, 1);
		SystemManage.unbindAdmin({ mp_id: currentRow.value.mp_id, u_id: item.u_id }).then(res => {
			if (res.Code == 200) {
				chargeTable.value.tableLoad();
				ElMessage.success("解绑成功！");
			} else {
				ElMessage.error(res.Message);
			}
		})
	}
	/**
	 * 
	 * 确定按钮
	 * 
	 * */
	const onConfirmEditAdmin = () => {
		let arr = [];
		if (onfirmManage.value.length == 0) {
			ElMessage.error("请选择管理人员！");
			return false;
		} else {
			onfirmManage.value.forEach(item => {
				arr.push(item.u_id);
			})
		}
		let params = {
			mp_id: currentRow.value.mp_id,
			u_id: arr.join(','),
		};
		SystemManage.addManagerBind(params).then(res => {
			if (res.Code == 200) {
				refEditAdmin.value.close();
				chargeTable.value.tableLoad();
				ElMessage.success("绑定成功！");
			} else {
				ElMessage.error(res.Message);
			}
		})
	}

	onMounted(() => {
		chargeTable.value.tableLoad();
	});
</script>

<style lang="scss">
	.payment {
		font-family: "Source Han Sans CN";

		.content {
			padding: 20px;
		}

		.title-num-green {
			color: var(--theme-color);
		}

		.select-quant {
			font-size: 20px;
			margin-left: 6px;
		}

		.add-dialog {
			.el-dialog__body {
				padding: 10px 15px 15px 30px;
			}
		}

		.border-bottom {
			border-bottom: 1px solid var(--border-color);
		}

		.contents {
			margin-top: 10px;
			/* overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			-webkit-line-clamp: 5; */
			color: var(--text-color);
		}

		.active-skin {
			color: var(--theme-color) !important;
			background-color: var(--search-bg-color) !important;
			position: relative;
			margin-top: 10px;

			.del-btn {
				position: absolute;
				right: 0;
				top: 0;
				height: 15px;
				width: 20px;
				border-radius: 0 0 0 50%;
				background-color: rgba(0, 0, 0, 0.5);
				color: #fff;
			}
		}
	}
</style>